import React from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <Box className="footer-container">
      <Container maxWidth="lg" className="footer-content-wrapper">
        <Box className="footer-content">
          <div className="centered-footer-content">
            <Typography variant="body2" className="footer-tagline">
              Portfolio built with lots of coffee and debugging
            </Typography>
            <span className="divider">|</span>
            <Typography variant="body2" className="footer-collaborate">
              Let's collaborate
            </Typography>
            <div className="social-icons">
              <IconButton 
                className="social-icon" 
                href="https://linkedin.com/in/yourusername" 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
               <FontAwesomeIcon icon={faLinkedinIn} />
              </IconButton>
              <IconButton 
                className="social-icon" 
                href="mailto:masery3@gatech.edu" 
                aria-label="Email"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </IconButton>
            </div>
          </div>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
