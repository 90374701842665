import React from 'react';

const style = document.createElement('style');
style.textContent = `
  .scroll-downs {
    position: relative;
    width: 34px;
    height: 55px;
    margin: 30px auto;
  }

  .mousey {
    width: 3px;
    padding: 10px 15px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }

  .scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
  }

  @keyframes scroll {
    0% { 
      opacity: 0; 
    }
    10% { 
      transform: translateY(0); 
      opacity: 1; 
    }
    100% { 
      transform: translateY(15px); 
      opacity: 0;
    }
  }
`;
document.head.appendChild(style);

const ArrowAnimation = () => {
  return (
    <div className="scroll-downs">
      <div className="mousey">
        <div className="scroller"></div>
      </div>
    </div>
  );
};

export default ArrowAnimation;