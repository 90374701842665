import React, { useState, useEffect, useRef } from 'react';
import './CompanyAnimation.css';

const CompanyAnimation = () => {
  const [activeCompany, setActiveCompany] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const companies = [
    { name: 'Delta', color: '#d73838' },
    { name: 'IBM', color: '#415dea' },
    { name: 'Microsoft', color: '#9ee353' }
  ];
  
  const nextCompanyRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate next company index
      const nextIndex = (activeCompany + 1) % companies.length;
      
      // Start animation
      setIsAnimating(true);
      
      // Update after animation completes
      setTimeout(() => {
        setActiveCompany(nextIndex);
        setIsAnimating(false);
      }, 500); // Match this to your CSS animation duration
      
    }, 2000); // Total time between transitions

    return () => clearInterval(interval);
  }, [activeCompany]);

  // Determine the next company to animate in
  const nextCompanyIndex = (activeCompany + 1) % companies.length;

  return (
    <div className="education-company-container">
      <div className="education-section">
        <span className="ms-text">M.S. HCI at</span>
        <span className="gt-text">Georgia Tech</span>
      </div>
      
      <span className="divider">|</span>
      
      <div className="experience-container">
        <span className="experience-text">Experience with</span>
        <div className="company-slider">
          {/* Current company */}
          <div 
            className={`company-name ${isAnimating ? 'slide-out' : ''}`}
            style={{ color: companies[activeCompany].color }}
          >
            {companies[activeCompany].name}
          </div>
          
          {/* Next company (animating in) */}
          {isAnimating && (
            <div 
              className="company-name slide-in"
              style={{ color: companies[nextCompanyIndex].color }}
            >
              {companies[nextCompanyIndex].name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyAnimation;