import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import ProjectPage from './ProjectPage';
import ProjectAtl from './ProjectAtl';
import AboutPage from './AboutPage';
import DeltaProject from './DeltaProject';
import IbmProject from './IbmProject';
import NavigationMenu from './NavigationMenu';
import Footer from './Footer'; // Import the Footer component
import { Box, Container, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

// Theme customization
const theme = createTheme({
  typography: {
    fontSize: 16,
  },
});

// Root component with shared layout
function AppWithLayout() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [active, setActive] = useState('work');
  const [isProjectPage, setIsProjectPage] = useState(false);
  const location = useLocation();
  
  // Forceful scroll to top on route changes
  useEffect(() => {
    // Scroll to top when location changes
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    
    // A second attempt with a slight delay
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, 50);
  }, [location.pathname]);
  
  // Update active state based on current route
  useEffect(() => {
    if (location.pathname === '/') {
      const params = new URLSearchParams(location.search);
      const section = params.get('section');
      if (section === 'work' || section === 'play') {
        setActive(section);
      } else {
        setActive('work'); // Default
      }
      setIsProjectPage(false);
    } else if (location.pathname.includes('/project') || 
             location.pathname.includes('/delta') || 
             location.pathname.includes('/ibm') || 
             location.pathname.includes('/atl-airport')) {
      // For project pages, keep active as 'work' but set isProjectPage to true
      setActive('work');
      setIsProjectPage(true);
    } else if (location.pathname.includes('/about')) {
      setActive('about');
      setIsProjectPage(false);
    }
  }, [location]);

  // CSS classes for the container based on active state
  // Add a specific class for project pages
  const containerClasses = `landing-container ${active} ${isProjectPage ? 'project' : ''}`;
  
  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Box className={containerClasses} sx={{ flexGrow: 1, position: 'relative' }}>
          <div className="glow-left-top"></div>
          <div className="glow-left-bottom"></div>
          <div className="glow-right-top"></div>
          <div className="glow-right-bottom"></div>
          
          <Container maxWidth={false} sx={{ maxWidth: '1400px' }} className="page-content">
            {/* Navigation menu that is consistently rendered across all routes */}
            <NavigationMenu 
              active={active} 
              setActive={setActive} 
              menuOpen={menuOpen} 
              setMenuOpen={setMenuOpen} 
              isProjectPage={isProjectPage}
            />
            
            {/* Routes are rendered inside the shared layout */}
            <Routes>
              <Route path="/" element={
                <LandingPage 
                  active={active} 
                  setActive={setActive} 
                  menuOpen={menuOpen} 
                  setMenuOpen={setMenuOpen} 
                />
              } />
              
              <Route path="/project" element={
                <ProjectPage 
                  setActive={setActive} 
                  menuOpen={menuOpen} 
                  setMenuOpen={setMenuOpen} 
                />
              } />
              
              <Route path="/delta" element={
                <DeltaProject 
                  setActive={setActive} 
                  menuOpen={menuOpen} 
                  setMenuOpen={setMenuOpen} 
                />
              } />
              
              <Route path="/ibm" element={
                <IbmProject 
                  setActive={setActive} 
                  menuOpen={menuOpen} 
                  setMenuOpen={setMenuOpen} 
                />
              } />
              
              <Route path="/atl-airport" element={
                <ProjectAtl 
                  setActive={setActive} 
                  menuOpen={menuOpen} 
                  setMenuOpen={setMenuOpen} 
                />
              } />
              
              <Route path="/about" element={
                <AboutPage 
                  setActive={setActive} 
                  menuOpen={menuOpen} 
                  setMenuOpen={setMenuOpen} 
                />
              } />
            </Routes>
          </Container>
        </Box>
        
        {/* Footer component outside the Box but inside the main div */}
        <Footer />
      </div>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Router>
      <AppWithLayout />
    </Router>
  );
}

export default App;