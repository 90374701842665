import React from 'react';
import './MetallicCard.css';
import { useNavigate } from 'react-router-dom';

const getRGBValues = (hex) => {
  hex = hex.replace('#', '');

  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
};

const MetallicCard = ({
  width,
  height,
  inView,
  cardRef,
  logoSrc,
  title,
  tags = [],
  description,
  projectLink,
  imageSrc,
  accentColor = '#e31837',
  imageAlt = 'Project mockup',
  className = '',
  logoSize = 24,
  imageHeight = '100%',
  imageWidth = '100%',
  imageTransform = 'none',
  imageObjectFit = 'cover',
  imageObjectPosition = 'center',
  imageClassName = '',
  showButton = true
}) => {
  // Use React Router's navigate function
  const navigate = useNavigate();

  const dynamicStyles = {
    '--accent-color': accentColor,
    '--accent-color-rgb': getRGBValues(accentColor),
    '--logo-size': `${logoSize}px`
  };

  const imageStyles = {
    height: imageHeight,
    width: imageWidth,
    transform: imageTransform,
    objectFit: imageObjectFit,
    objectPosition: imageObjectPosition
  };

  const containerStyles = {
    ...(width ? { width } : {}),
    ...(height ? { height } : {})
  };

  const cardStyles = {
    ...dynamicStyles,
    ...(height ? { height } : {})
  };

  // Force scroll to top and then navigate
  const navigateWithScroll = (link) => {
    if (link.startsWith('http')) {
      // External link - open in new tab
      window.open(link, '_blank');
    } else {
      // For internal navigation: 
      // 1. Set a session storage flag
      sessionStorage.setItem('shouldScrollTop', 'true');
      // 2. Navigate
      navigate(link, { replace: true });
    }
  };

  // Updated function to handle card click
  const handleCardClick = () => {
    if (projectLink) {
      navigateWithScroll(projectLink);
    }
  };

  return (
    <div className="cards-container" style={containerStyles}>
      <div
        className={`metallic-card ${inView ? 'slide-up-active' : ''} ${className}`}
        ref={cardRef}
        style={cardStyles}
        onClick={handleCardClick}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          // Add keyboard accessibility - activate on Enter or Space key
          if (e.key === 'Enter' || e.key === ' ') {
            handleCardClick();
          }
        }}
      >
        {/* Hover effects */}
        <div className="hover-effects">
          <div className="gird-container-metalicard">
            {[...Array(16)].map((_, i) => (
              <div
                key={i}
                className={`grid-cell ${[0, 2, 5, 7, 8, 10].includes(i) ? 'colored' : ''}`}
              />
            ))}
          </div>

          {[0, 1, 2].map((i) => (
            <div
              key={`h${i}`}
              className="line line-horizontal"
              style={{ top: `${25 + i * 25}%` }}
            />
          ))}

          {[0, 1, 2].map((i) => (
            <div
              key={`v${i}`}
              className="line line-vertical"
              style={{ left: `${25 + i * 25}%` }}
            />
          ))}
        </div>

        <div className="card-text">
          <div className="card-para">
            <div className="logo-title">
              <div className="logo-wrapper">
                <img
                  src={logoSrc}
                  alt={`${title} Logo`}
                  className="company-logo"
                />
              </div>
              <h3 className="company-title">{title}</h3>
            </div>
            <div className="tags">
              {tags.map((tag, index) => (
                <p key={index}>{tag}</p>
              ))}
            </div>
            <p>{description}</p>
            {showButton && (
              <button
                className="see-project-button"
                onClick={(e) => {
                  // Prevent the button click from triggering the card click
                  e.stopPropagation();
                  navigateWithScroll(projectLink);
                }}
              >
                Learn more
              </button>
            )}
          </div>
        </div>

        <div className={`card-image ${imageClassName}`}>
          <img
            src={imageSrc}
            alt={imageAlt}
            style={imageStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default MetallicCard;