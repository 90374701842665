import React from 'react';
import { Box, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFile, faComment } from '@fortawesome/free-solid-svg-icons';
import Rive from '@rive-app/react-canvas';
import helloRiv from './assets/interactive_cta-2.riv';
import { useNavigate } from 'react-router-dom';
import 'animate.css';

function NavigationMenu({ active, setActive, menuOpen, setMenuOpen, isProjectPage }) {
    const navigate = useNavigate();

    const handleButtonClick = (value) => {
        if (value === 'work' || value === 'play') {
            setActive(value);
            navigate(`/?section=${value}`, { replace: true });
        } else if (value === 'about') {
            navigate('/about', { replace: true });
        }
        setMenuOpen(false);
    };

    const handleCircleHeadClick = () => {
        // Only navigate to home if active is 'work'
        if (active === 'work') {
            navigate('/', { replace: true });
        }
    };

    // Determine if work button should be highlighted (when on work tab or project pages)
    const isWorkActive = active === 'work' || isProjectPage;

    return (
        <Box className="menu-top" sx={{ p: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div
                className="animate__animated animate__bounceIn circle head"
                onClick={active === 'work' ? handleCircleHeadClick : undefined}
            >
                <Rive
                    src={helloRiv}
                    stateMachines="State Machine 1"
                    autoplay={true}
                    className="rive-animation"
                    style={{ pointerEvents: active === 'work' ? 'auto' : 'none' }}
                />
            </div>
            <Box className="animate__animated animate__flipInY switch">
                <Button onClick={() => handleButtonClick('work')} sx={isWorkActive ? {
                    textTransform: 'none',
                    fontWeight: '700',
                    padding: '10px 20px',
                    backgroundColor: 'rgba(178, 178, 178, 0.15)',
                    border: '2px solid rgba(59, 59, 50, 0)',
                    color: '#fff',
                    width: '130px',
                    borderRadius: '35px',
                    ':hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                } : {
                    textTransform: 'none',
                    fontWeight: '700',
                    padding: '10px 20px',
                    backgroundColor: 'transparent',
                    color: '#fff',
                    borderRadius: '35px',
                    width: '130px',
                    border: '2px solid rgba(59, 59, 50, 0)',
                }}>
                    Professional
                </Button>
                <Button onClick={() => handleButtonClick('play')} sx={active === 'play' ? {
                    textTransform: 'none',
                    fontWeight: '700',
                    padding: '10px 20px',
                    backgroundColor: 'rgba(178, 178, 178, 0.15)',
                    border: '2px solid rgba(59, 59, 50, 0)',
                    color: '#fff',
                    width: '130px',
                    borderRadius: '35px',
                    ':hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                } : {
                    textTransform: 'none',
                    fontWeight: '700',
                    padding: '10px 20px',
                    backgroundColor: 'transparent',
                    color: '#fff',
                    borderRadius: '35px',
                    width: '130px',
                    border: '2px solid rgba(59, 59, 50, 0)',
                }}>
                    Personal
                </Button>
            </Box>
            <div className="animate__animated animate__bounceIn wrapper">
                <input type="checkbox" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} />
                <div className="fab"></div>
                <div className="fac">
                    <button onClick={() => navigate('/about', { replace: true })}>
                        <FontAwesomeIcon icon={faUser} />
                    </button>
                    <button onClick={() => window.open('https://drive.google.com/file/d/1AIk3TiVhHUQcofM_D6FkESGknWe4s3cX/view?usp=sharing', '_blank')}>
                        <FontAwesomeIcon icon={faFile} />
                    </button>
                    <button onClick={() => {}}>
                        <a href="mailto:masery3@gatech.edu" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <FontAwesomeIcon icon={faComment} />
                        </a>
                    </button>
                </div>
            </div>
        </Box>
    );
}

export default NavigationMenu;