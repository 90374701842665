import { React, useEffect, useState, useRef } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import './IbmProject.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import MetallicCard from './MetallicCard';
import { useInView } from 'react-intersection-observer';
import navigation from '../src/assets/ProjectDelta/navigation.mp4';
import videomode from '../src/assets/ProjectDelta/videomode.mp4';
import gamemode from '../src/assets/ProjectDelta/gamemode.mp4';

const IbmProject = ({ menuOpen, setMenuOpen, setActive }) => {
    const [isFloatingVisible, setIsFloatingVisible] = useState(false);
    const overviewRef = useRef(null);
    const navigate = useNavigate();
    const [userClicked, setUserClicked] = useState(false);

    // Force scroll to top when this component mounts
    useEffect(() => {
        // Set active to 'project'
        setActive('project');
        
        // Scroll to top forcefully - using multiple methods
        window.scrollTo(0, 0);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        
        // And also with a small delay to ensure it happens after render
        setTimeout(() => {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }, 100);
    }, [setActive]);

    const handleScroll = () => {
        if (overviewRef.current) {
            const rect = overviewRef.current.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            setIsFloatingVisible(isVisible);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call it to check the initial position
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavigation = (section) => {
        navigate(`/?section=${section}`, { replace: true });
    };

    // Directly set the active section on click without additional logic
    const handleSectionClick = (sectionId) => {
        setUserClicked(true); // Indicate that this change was triggered by a user click
        setActiveSection(sectionId);
        scrollToSection(sectionId);

        // Optionally, open the corresponding collapsible based on the clicked section
        const collapsibleSectionToOpen = mapContentSectionToSidebarSection(sectionId);
        if (collapsibleSectionToOpen) {
            setOpenSections({
                ...Object.keys(openSections).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {}),
                [collapsibleSectionToOpen]: true,
            });
        }
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // State to control the visibility of each collapsible content
    const [openSections, setOpenSections] = useState({});

    // Function to toggle collapsible section
    const toggleSection = (sectionId) => {
        setOpenSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    };

    // Ref for the content to measure its height for animation
    const contentRef = useRef({});

    const handleToggleAndScroll = (toggleSectionId, scrollSectionId) => {
        toggleSection(toggleSectionId); // Toggle the visibility of the section
        setTimeout(() => { // Set a timeout to wait for the toggle animation
            scrollToSection(scrollSectionId); // Scroll to the section after the toggle
        }, 0); // Adjust the timeout duration based on your toggle animation duration
    };

    const [activeSection, setActiveSection] = useState('');
    const sectionRefs = {
        'project-overview': useRef(null),
        'stakeholders': useRef(null),
        'problem-statement': useRef(null),
        'possible-solution': useRef(null),
        'design-thinking-process': useRef(null),
        'survey': useRef(null),
        'product-walkthrough': useRef(null),
        'competitive-analysis': useRef(null),
        'interview': useRef(null),
        'key-findings': useRef(null),
        'user-needs': useRef(null),
        'empathy-map': useRef(null),
        'design-implications': useRef(null),
        'feature-matrix': useRef(null),
        'affinity-diagram': useRef(null),
        'initial-sketches': useRef(null),
        'iteration feedback': useRef(null),
        'wireframes': useRef(null),
        'iteration-feedback': useRef(null),
        'design-system': useRef(null),
        'prototype': useRef(null),
        'heuristic-evaluation': useRef(null),
        'usability': useRef(null),
    };

    const [activeVideo, setActiveVideo] = useState('video-1');
    const videoRefs = {
        'video-1': useRef(null),
        'video-2': useRef(null),
        'video-3': useRef(null)
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            let visibleSections = entries.filter(entry => entry.isIntersecting).map(entry => entry.target.id);

            if (visibleSections.length && !userClicked) { // Check if change is not due to user click
                let currentSection = visibleSections[0];
                setActiveSection(currentSection);

                // Logic to open only the corresponding collapsible section
                const collapsibleSectionToOpen = mapContentSectionToSidebarSection(currentSection);
                if (collapsibleSectionToOpen) {
                    setOpenSections(prevState => {
                        let newState = Object.keys(prevState).reduce((acc, key) => {
                            acc[key] = false; // Close all other sections
                            return acc;
                        }, {});
                        newState[collapsibleSectionToOpen] = true; // Open the current section
                        return newState;
                    });
                }
            } else {
                setUserClicked(false); // Reset userClicked back to false for automatic changes
            }
        }, { rootMargin: '0px', threshold: 0.5 });

        Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });

        return () => Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.unobserve(ref.current);
        });
    }, [userClicked]); // Include userClicked in the dependency array

    // Function to map content section IDs to sidebar section keys
    function mapContentSectionToSidebarSection(contentSectionId) {
        // Example mapping, update according to your actual IDs and keys
        const mapping = {
            'design-thinking-process': 'design-thinking-process',
            'survey': 'empathize',
            'product-walkthrough': 'empathize',
            'competitive-analysis': 'empathize',
            'interview': 'empathize',
            'key-findings': 'define',
            'user-needs': 'define',
            'empathy-map': 'define',
            'design-implications': 'define',
            'feature-matrix': 'ideate',
            'affinity-diagram': 'ideate',
            'initial-sketches': 'ideate',
            'iteration feedback': 'ideate',
            'wireframes': 'design',
            'iteration-feedback': 'design',
            'design-system': 'design',
            'prototype': 'design',
            'heuristic-evaluation': 'test',
            'usability': 'test',
        };
        return mapping[contentSectionId] || contentSectionId;
    }

    const [cardRef, cardInView] = useInView({ triggerOnce: true, threshold: 0 });

    // Function to handle video click
    const handleVideoClick = (videoId) => {
        if (activeVideo === videoId) {
            // If clicking the currently playing video, toggle play/pause
            const video = videoRefs[videoId].current;
            if (video.paused) {
                video.play();
            } else {
                video.pause();
                // Make no video active if current one is paused
                setActiveVideo(null);
            }
        } else {
            // If clicking a different video, pause current and play new
            if (activeVideo) {
                const currentVideo = videoRefs[activeVideo].current;
                if (currentVideo) {
                    currentVideo.pause();
                }
            }

            // Play the new video
            const newVideo = videoRefs[videoId].current;
            if (newVideo) {
                newVideo.play();
                setActiveVideo(videoId);
            }
        }
    };

    return (
        // Remove ThemeProvider - now handled in App.js
        <Container maxWidth={false} disableGutters className="ibm-page-wrapper">
            <div className="projectDelta-section1">
                <Container maxWidth="lg">
                    {/* Remove NavigationMenu - now handled in App.js */}
                    
                    <div className="delta-card-wrapper" style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '4rem' }}>
                        <MetallicCard
                            width="960px"
                            inView={cardInView}
                            cardRef={cardRef}
                            logoSrc={require('../src/assets/project4/bee.png')}
                            title="IBM"
                            tags={['UX Research', 'UX Design', 'Web', 'LLM']}
                            description="Building a scalable web interface for evaluating LLM-generated outputs"
                            projectLink="/ibm"
                            imageSrc={require('../src/assets/project1/IBMA.png')}
                            accentColor="#c4c4c4"
                            logoSize={24}
                            imageHeight="130%"
                            imageWidth="130%"
                            imageTransform="translate(2.5rem, 1rem)"
                            imageObjectFit="contain"
                            imageObjectPosition="center"
                            imageClassName="card-image-delta"
                            showButton={false}
                            className="metallic-card-indiv"
                        />
                    </div>

                    <div className="project-container" style={{ maxWidth: '930px', margin: '0 auto', padding: '0 20px' }}>
                        <div className="animate__animated animate__fadeIn animate__delay-900ms">

                            <div className="info-case-grid">
                                {/* Project Context */}
                                <div>
                                    <h1 className="item-text">Project Context</h1>
                                    <p>Capstone Project</p>
                                    <p>Aug - Present</p>
                                    <p>UX Designer, UX Engineer</p>
                                </div>

                                {/* Team */}
                                <div>
                                    <h1 className="item-text">Team</h1>
                                    <p>2 UX Designers</p>
                                    <p>2 UX Researchers</p>
                                    <p>1 Manager</p>
                                </div>

                                {/* Tools */}
                                <div>
                                    <h1 className="item-text">Tools</h1>
                                    <p>Figma, React</p>
                                    <p>Swift, Blender</p>
                                </div>
                            </div>

                            {/* Process */}
                            <div className="process add-space-process">
                                <h1 className="item-text">NDA Disclosure</h1>
                                <p>
                                    I am currently collaborating with IBM Research on developing evaluation interfaces for LLM-generated content. <span style={{ fontWeight: 700 }}>Due to confidentiality agreements,</span> I am unable to disclose specific details until project completion. This case study will be updated with comprehensive documentation of the process and outcomes upon finalization of the work.                                        </p>
                            </div>
                            <div className="chi-paper-button-wrapper">
                                <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className="chi-paper-button-link">
                                    <button className="chi-paper-button">
                                        Read Paper Submitted to CHI Conference
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </Container>
    );
};

export default IbmProject;