import React from 'react';

const GradientText = ({ 
  text = "Hello, I am Amin!", 
  className = "",
  customStyles = {}
}) => {
  return (
    <div>
      <h1 className={`gradient-title ${className}`} style={customStyles}>
        {text}
      </h1>
      <style jsx>{`
        @font-face {
          font-family: 'OpenSauceSans';
          src: url('/fonts/OpenSauceSans-SemiBold.ttf') format('truetype');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }

        .gradient-title {
          color: #fff;
          letter-spacing: -.02em;
          font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
          font-size: 120px;
          font-weight: 500;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(to top, #363a3f, #fff 60%);
          -webkit-background-clip: text;
          background-clip: text;
          margin-top: 1.5rem;
          margin-bottom: 0rem;
        }

        @media screen and (max-width: 767px) {
          .gradient-title {
            font-size: 48px;
          }
        }
      `}</style>
    </div>
  );
};

export default GradientText;