import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';
import 'animate.css';

function AboutPage({ menuOpen, setMenuOpen, setActive }) {
    const navigate = useNavigate();

    // Force scroll to top when this component mounts
    useEffect(() => {
        // Set active to 'about'
        setActive('about');
        
        // Scroll to top forcefully - using multiple methods
        window.scrollTo(0, 0);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        
        // And also with a small delay to ensure it happens after render
        setTimeout(() => {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }, 100);
    }, [setActive]);

    const handleNavigation = (section) => {
        // Navigate to LandingPage with a query indicating the section
        navigate(`/?section=${section}`, { replace: true });
    };
    
    return (
        // Remove ThemeProvider and outer Box (now handled in App.js)
        <Box className="content">
            {/* Your Project Page Content Here */}
            <div className="section-active about-width">
                <div className="title-container">
                    {/* <Box component="h1" className="main-title about-title">About</Box> */}
                    {/* <p>More information about me...</p> */}
                </div>

                <div className="about-section">
                    <div className="about-row">
                        <div className="about-text">
                            <Box component="h1" className="animate__animated animate__fadeInDown main-title about-title">About</Box>
                            <p className="animate__animated animate__fadeIn animate__delay-900ms">I find joy in designing, coding, and animating things. My approach to design is research-driven, ensuring that each design is not only aesthetically pleasing but also backed by solid understanding and analysis. Currently, my research interest lies in exploring the potential of generative AI in design, and how it can streamline the design process. One last thing...I'm a cat dad, with two super fluffy cats who love to make sure I am getting my work done.</p>
                        </div>
                        <div className="animate__animated animate__bounceIn about-image-container">
                            <img className="about-image" src="https://i.postimg.cc/1zbdnX2q/ezgif-3-ae7e80b4f3.gif" alt="About Me" />
                        </div>
                    </div>

                    <div className="image-section-wrapper">
                        <Box component="h1" className="animate__animated animate__fadeInDown main-title not-working">When I am not working, I am...</Box>

                        <div className="image-row">
                            <div className="animate__animated animate__bounceIn image-text-container">
                                <div className="about-image-container">
                                    <img className="about-image" src="https://i.postimg.cc/CMrJQWPP/ezgif-4-ded3f0ffa7.gif" alt="More About Me" />
                                </div>
                                <p className="animate__animated animate__fadeInDown image-description">Dancing Salsa</p>
                            </div>
                            <div className="animate__animated animate__bounceIn image-text-container">
                                <div className="about-image-container">
                                    <img className="about-image" src="https://i.postimg.cc/V6yDDMKY/ezgif-4-f0ac75df6b.gif" alt="More About Me" />
                                </div>
                                <p className="animate__animated animate__fadeInDown image-description">Freediving</p>
                            </div>
                            <div className="animate__animated animate__bounceIn image-text-container">
                                <div className="about-image-container">
                                    <img className="about-image" src="https://i.postimg.cc/LXJh6CfT/ezgif-2-e0068b1d4d.gif" alt="More About Me" />
                                </div>
                                <p className="animate__animated animate__fadeInDown animate__delay-1200ms image-description">Watching memes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
}

export default AboutPage;