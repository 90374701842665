import { React, useEffect, useState, useRef } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import './DeltaProject.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fontSize } from '@mui/system';
import 'animate.css';
import MetallicCard from './MetallicCard';
import { useInView } from 'react-intersection-observer';
import navigation from '../src/assets/ProjectDelta/navigation.mp4';
import videomode from '../src/assets/ProjectDelta/videomode.mp4';
import gamemode from '../src/assets/ProjectDelta/gamemode.mp4';

const DeltaProject = ({ menuOpen, setMenuOpen, setActive }) => {


    // Set the active state to 'project' when this component mounts
    useEffect(() => {
        setActive('project');
    }, [setActive]);


     // Rest of your component stays the same
     const [isFloatingVisible, setIsFloatingVisible] = useState(false);
     const overviewRef = useRef(null);
     const navigate = useNavigate();
     const [userClicked, setUserClicked] = useState(false);
     

    const handleScroll = () => {
        if (overviewRef.current) {
            const rect = overviewRef.current.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            setIsFloatingVisible(isVisible);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call it to check the initial position
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavigation = (section) => {
        navigate(`/?section=${section}`, { replace: true });
    };

    // Directly set the active section on click without additional logic
    const handleSectionClick = (sectionId) => {
        setUserClicked(true); // Indicate that this change was triggered by a user click
        setActiveSection(sectionId);
        scrollToSection(sectionId);

        // Optionally, open the corresponding collapsible based on the clicked section
        const collapsibleSectionToOpen = mapContentSectionToSidebarSection(sectionId);
        if (collapsibleSectionToOpen) {
            setOpenSections({
                ...Object.keys(openSections).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {}),
                [collapsibleSectionToOpen]: true,
            });
        }
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // State to control the visibility of each collapsible content
    const [openSections, setOpenSections] = useState({});
    const [activeSection, setActiveSection] = useState('');

    // Function to toggle collapsible section
    const toggleSection = (sectionId) => {
        setOpenSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    };

    const handleToggleAndScroll = (toggleSectionId, scrollSectionId) => {
        toggleSection(toggleSectionId);
        setTimeout(() => {
            scrollToSection(scrollSectionId);
        }, 0);
    };

    const sectionRefs = {
        'project-overview': useRef(null),
        'stakeholders': useRef(null),
        'problem-statement': useRef(null),
        'possible-solution': useRef(null),
        'design-thinking-process': useRef(null),
        'survey': useRef(null),
        'product-walkthrough': useRef(null),
        'competitive-analysis': useRef(null),
        'interview': useRef(null),
        'key-findings': useRef(null),
        'user-needs': useRef(null),
        'empathy-map': useRef(null),
        'design-implications': useRef(null),
        'feature-matrix': useRef(null),
        'affinity-diagram': useRef(null),
        'initial-sketches': useRef(null),
        'iteration feedback': useRef(null),
        'wireframes': useRef(null),
        'iteration-feedback': useRef(null),
        'design-system': useRef(null),
        'prototype': useRef(null),
        'heuristic-evaluation': useRef(null),
        'usability': useRef(null),
    };

    const [expandedObservation, setExpandedObservation] = useState(false);
    const [expandedWalkthrough, setExpandedWalkthrough] = useState(false);
    const [expandedInterviews, setExpandedInterviews] = useState(false);
    const [expandedConceptualization, setExpandedConceptualization] = useState(false);
    const [activeCarouselItem, setActiveCarouselItem] = useState('item-1');

    const [activeVideo, setActiveVideo] = useState('video-1');
    const videoRefs = {
        'video-1': useRef(null),
        'video-2': useRef(null),
        'video-3': useRef(null)
    };

    useEffect(() => {
        // Pause all videos first
        Object.keys(videoRefs).forEach(key => {
            if (videoRefs[key].current) {
                videoRefs[key].current.pause();
            }
        });

        // Play only the active video
        if (activeCarouselItem && videoRefs[`video-${activeCarouselItem.split('-')[1]}`]?.current) {
            const activeVideo = videoRefs[`video-${activeCarouselItem.split('-')[1]}`].current;
            activeVideo.currentTime = 0; // Reset to beginning
            activeVideo.play();
        }
    }, [activeCarouselItem]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            let visibleSections = entries.filter(entry => entry.isIntersecting).map(entry => entry.target.id);

            if (visibleSections.length && !userClicked) { // Check if change is not due to user click
                let currentSection = visibleSections[0];
                setActiveSection(currentSection);

                // Logic to open only the corresponding collapsible section
                const collapsibleSectionToOpen = mapContentSectionToSidebarSection(currentSection);
                if (collapsibleSectionToOpen) {
                    setOpenSections(prevState => {
                        let newState = Object.keys(prevState).reduce((acc, key) => {
                            acc[key] = false; // Close all other sections
                            return acc;
                        }, {});
                        newState[collapsibleSectionToOpen] = true; // Open the current section
                        return newState;
                    });
                }
            } else {
                setUserClicked(false); // Reset userClicked back to false for automatic changes
            }
        }, { rootMargin: '0px', threshold: 0.5 });

        Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });

        return () => Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.unobserve(ref.current);
        });
    }, [userClicked]);

    // Function to map content section IDs to sidebar section keys
    function mapContentSectionToSidebarSection(contentSectionId) {
        // Example mapping, update according to your actual IDs and keys
        const mapping = {
            'design-thinking-process': 'design-thinking-process',
            'survey': 'empathize',
            'product-walkthrough': 'empathize',
            'competitive-analysis': 'empathize',
            'interview': 'empathize',
            'key-findings': 'define',
            'user-needs': 'define',
            'empathy-map': 'define',
            'design-implications': 'define',
            'feature-matrix': 'ideate',
            'affinity-diagram': 'ideate',
            'initial-sketches': 'ideate',
            'iteration feedback': 'ideate',
            'wireframes': 'design',
            'iteration-feedback': 'design',
            'design-system': 'design',
            'prototype': 'design',
            'heuristic-evaluation': 'test',
            'usability': 'test',
        };
        return mapping[contentSectionId] || contentSectionId;
    }

    const [cardRef, cardInView] = useInView({ triggerOnce: true, threshold: 0 });

    // Function to handle video click
    const handleVideoClick = (videoId) => {
        // Just switch to this carousel item
        setActiveCarouselItem(videoId.replace('video-', 'item-'));
    };

    return (
        // Remove ThemeProvider and outermost Box as they are now in MainLayout
        <Box className="project-inner-container">
            <Container maxWidth={false} className="project-content" disableGutters>
                {/* Your Project Page Content Here */}
                <div className="projectDelta-section1">
                    <Container maxWidth={"lg"}>
                        <div className="delta-card-wrapper" style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '4rem' }}>
                        <MetallicCard
                            width="960px"
                            inView={cardInView}
                            cardRef={cardRef}
                            logoSrc={require('../src/assets/project3/delta.png')}
                            title="Delta Air Lines"
                            tags={['UX Design', 'UX Engineering', 'Mobile', 'R&D']}
                            description="Designed & developed mobile control for Delta's in-flight entertainment touchscreens"
                            projectLink="/delta"
                            imageSrc={require('../src/assets/project1/deltamock.png')}
                            accentColor="#c4c4c4"
                            logoSize={24}
                            imageHeight="400px"
                            imageWidth="110%"
                            imageTransform="translate(2.5rem, 1rem)"
                            imageObjectFit="contain"
                            imageObjectPosition="center"
                            imageClassName="card-image-delta"
                            showButton={false}
                            className="metallic-card-indiv"
                        />
                        </div>

                        {/* Rest of the Delta project content */}
                        <div className="project-container" style={{ maxWidth: '930px', margin: '0 auto', padding: '0 20px' }}>
                            <div className="animate__animated animate__fadeIn animate__delay-900ms">
                                <div className="process">
                                    <p>
                                        During my time at Delta, I worked on various projects focused on improving passenger experience across different touchpoints. <span style={{ fontWeight: 700 }}>Due to confidentiality agreements,</span> I only have permission to share details about this touchscreen enhancement project. Some specific information has been modified to protect proprietary aspects while preserving the core UX process and learnings.
                                    </p>
                                </div>
                                <div className="info-case-grid">
                                    {/* Project Context */}
                                    <div>
                                        <h1 className="item-text">Project Context</h1>
                                        <p>Internship</p>
                                        <p>May - Aug 2024</p>
                                        <p>UX Designer, UX Engineer</p>
                                    </div>

                                    {/* Team */}
                                    <div>
                                        <h1 className="item-text">Team</h1>
                                        <p>3 UX Designers</p>
                                        <p>2 R&D Engineer</p>
                                        <p>1 Strategist</p>
                                    </div>

                                    {/* Tools */}
                                    <div>
                                        <h1 className="item-text">Tools</h1>
                                        <p>Figma, React</p>
                                        <p>Swift, Blender</p>
                                    </div>
                                </div>

                                {/* Process */}
                                <div className="process delta-process">
                                    <h1 className="item-text">My Contributions</h1>
                                    <p>
                                        I led the end-to-end UX process from research through implementation. During the research phase, I conducted user interviews, performed ethnographic observations, product walkthroughs, and synthesized findings through affinity mapping and journey mapping. In the design phase, I led ideation workshops, created wireframes and interactive prototypes, and conducted usability testing to validate and refine the solution.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                    <div className="content-wrapper">



                        <div className="project-overview-content">

                            <div className="project1-section2">
                                {/* <Container style={{ maxWidth: '1100px' }} > */}
                                <Container style={{ maxWidth: '980px', marginTop: '3rem', paddingBottom: '3rem' }}>

                                    <div ref={overviewRef}>
                                        <div className="">


                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story-delta" >The Problem</h1>

                                            <div className="ife-grid-container drawing-grid">

                                                <div className="problem-container">

                                                    {/* <p className="paragraph-problem">
                                                        Passengers experience <span className='make-it-bold'> physical strain and frustration </span> when interacting with Delta's in-flight entertainment (IFE) touchscreens, while children quickly <span className='make-it-bold'>lose interest</span> in limited game options , and engineers seek a solution to <span className='make-it-bold'>Bluetooth signal interference</span> when passengers connect personal devices.
                                                    </p> */}
                                                    <p className="paragraph-problem">
                                                        Delta's touchscreens sit <span className='make-it-bold'> 32-40 inches </span> away, forcing uncomfortable reaching and creating  <span className='make-it-bold'> poor navigation and gaming experiences</span>. Everyone has a smartphone in their hand, yet can't use it with the screen they're struggling to reach. I noticed this disconnect and saw it as an opportunity to enhance the passenger experience.
                                                    </p>
                                                </div>

                                                <div className="ife-image-area">
                                                    <div className="ife-image-background"></div>
                                                    <img
                                                        src={require('../src/assets/ProjectDelta/storybord.jpg')}
                                                        alt="Travelers observing IFE system"
                                                    />
                                                </div>
                                            </div>


                                            {/* <div className="problem-container">

                                                <p className="paragraph-problem">
                                                    Passengers experience <span className='make-it-bold'> physical strain and frustration </span> when interacting with Delta's in-flight entertainment (IFE) touchscreens, while children quickly <span className='make-it-bold'>lose interest</span> in limited game options , and engineers seek a solution to <span className='make-it-bold'>Bluetooth signal interference</span> when passengers connect personal devices.
                                                </p>
                                            </div> */}

                                        </div>
                                    </div>
                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">


                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story-delta first-header" >The Solution</h1>
                                            {/* <p className="normal-para">
                                                I redesigned the IFE experience by introducing three key features:
                                            </p> */}

                                            <div className="carousel-container">
                                                <div className="container">
                                                    <input
                                                        type="radio"
                                                        name="slider"
                                                        id="item-1"
                                                        checked={activeCarouselItem === 'item-1'}
                                                        onChange={() => setActiveCarouselItem('item-1')}
                                                    />
                                                    <input
                                                        type="radio"
                                                        name="slider"
                                                        id="item-2"
                                                        checked={activeCarouselItem === 'item-2'}
                                                        onChange={() => setActiveCarouselItem('item-2')}
                                                    />
                                                    <input
                                                        type="radio"
                                                        name="slider"
                                                        id="item-3"
                                                        checked={activeCarouselItem === 'item-3'}
                                                        onChange={() => setActiveCarouselItem('item-3')}
                                                    />

                                                    <div className="cards">
                                                        <label className="card" htmlFor="item-1" id="feature-item-1">
                                                            <video
                                                                ref={videoRefs['video-1']}
                                                                muted
                                                                playsInline
                                                                loop
                                                            >
                                                                <source src={navigation} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>

                                                        </label>
                                                        <label className="card" htmlFor="item-2" id="feature-item-2">
                                                            <video
                                                                ref={videoRefs['video-2']}
                                                                muted
                                                                playsInline
                                                                loop
                                                            >
                                                                <source src={videomode} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>

                                                        </label>
                                                        <label className="card" htmlFor="item-3" id="feature-item-3">
                                                            <video
                                                                ref={videoRefs['video-3']}
                                                                muted
                                                                playsInline
                                                                loop
                                                            >
                                                                <source src={gamemode} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>

                                                        </label>
                                                    </div>

                                                    <div className="player">
                                                        <div className="upper-part">
                                                            <div className="circle-icon">
                                                                {/* {activeCarouselItem === 'item-1' && <div className="delta-solution-circle active">1</div>}
                                                                {activeCarouselItem === 'item-2' && <div className="delta-solution-circle active">2</div>}
                                                                {activeCarouselItem === 'item-3' && <div className="delta-solution-circle active">3</div>} */}
                                                            </div>
                                                            <div className="info-area" id="features-info">
                                                                <label className={`feature-info ${activeCarouselItem === 'item-1' ? 'active' : ''}`} id="feature-info-1">
                                                                    <div className="title-container-caras">
                                                                        <div className="feature-icon remote-icon">
                                                                            <svg viewBox="0 0 24 24" width="34" height="34" fill="#c46c6c" style={{ transform: 'rotate(90deg)' }}>
                                                                                <path d="M15 9H9c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2zm-3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM7.05 6.05l1.41 1.41C9.37 6.56 10.62 6 12 6s2.63.56 3.54 1.46l1.41-1.41C15.68 4.78 13.93 4 12 4s-3.68.78-4.95 2.05zM12 0C8.96 0 6.21 1.23 4.22 3.22l1.41 1.41C7.26 3.01 9.51 2 12 2s4.74 1.01 6.36 2.64l1.41-1.41C17.79 1.23 15.04 0 12 0z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="title">Phone as Remote Control</div>
                                                                    </div>
                                                                    <div className="description">Developed a gesture-based remote control system allowing passengers to navigate the touchscreen content effortlessly from their personal devices, eliminating physical strain and enhancing accessibility.</div>
                                                                </label>

                                                                <label className={`feature-info ${activeCarouselItem === 'item-2' ? 'active' : ''}`} id="feature-info-2">
                                                                    <div className="title-container-caras">
                                                                        <div className="feature-icon audio-icon">
                                                                            <svg viewBox="0 0 24 24" width="34" height="34" fill="#c46c6c" >
                                                                                <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="title">Wireless Audio Bridge</div>
                                                                    </div>
                                                                    <div className="description">Implemented WebRTC protocol to stream audio from the touchscreen directly to passenger devices, enabling seamless personal headphone connectivity without signal interference.</div>
                                                                </label>

                                                                <label className={`feature-info ${activeCarouselItem === 'item-3' ? 'active' : ''}`} id="feature-info-3">
                                                                    <div className="title-container-caras">
                                                                        <div className="feature-icon gaming-icon">
                                                                            <svg viewBox="0 0 24 24" width="34" height="34" fill="#c46c6c" >
                                                                                <path d="M21 6H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-10 7H8v3H6v-3H3v-2h3V8h2v3h3v2zm4.5 2c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4-3c-.83 0-1.5-.67-1.5-1.5S18.67 9 19.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="title">Interactive Gaming Experience</div>
                                                                    </div>
                                                                    <div className="description">Created an adaptive controller interface that transforms smartphones into versatile game controllers, significantly expanding Delta's gaming capabilities and passenger engagement.</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>


                                    <div ref={overviewRef}>


                                        <div className="first-frame-delta">
                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story-delta" >The Impact</h1>

                                            <div className="delta-features-container-impact">
                                                <div className="delta-feature-header">
                                                    <div className="delta-solution-circle Impact-circle" style={{ marginRight: "20px" }}>1</div>
                                                    <p className="Impact-title">
                                                        <span className="make-it-mediam">Enhanced Accessibility: </span>Improved the touchscreen usability for all passengers through remote control functionality, creating a more inclusive flight experience.
                                                    </p>
                                                </div>
                                                <div className="delta-feature-header" style={{ marginTop: "20px" }}>
                                                    <div className="delta-solution-circle Impact-circle" style={{ marginRight: "20px" }}>2</div>
                                                    <p className="Impact-title">
                                                        <span className="make-it-mediam">Seamless Audio Integration:</span> Solved Bluetooth interference issues while allowing passengers to use their own headphones, improving comfort and reducing airline costs.
                                                    </p>
                                                </div>
                                                <div className="delta-feature-header" style={{ marginTop: "20px" }}>
                                                    <div className="delta-solution-circle Impact-circle" style={{ marginRight: "20px" }}>3</div>
                                                    <p className="Impact-title">
                                                        <span className="make-it-mediam"> Engagement Transformation:</span> Increased gaming engagement among families with children through interactive gameplay options, enhancing passenger satisfaction.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div ref={overviewRef}>


                                        <div className="first-frame-delta">

                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story-delta" >The Process</h1>

                                            <div className="process-cards-container">
                                                <div className="process-card">
                                                    <h2 className="process-title">Research & Discovery</h2>
                                                    <p className="process-description">
                                                        Conducted user interviews and ethnographic observations to identify pain points.
                                                    </p>
                                                    <div className="process-duration">2 Weeks</div>
                                                </div>

                                                <div className="process-card">
                                                    <h2 className="process-title">Empathize & Define</h2>
                                                    <p className="process-description">
                                                        Synthesized research into journey maps. Defined user needs & design goals.
                                                    </p>
                                                    <div className="process-duration">2 Weeks</div>
                                                </div>

                                                <div className="process-card">
                                                    <h2 className="process-title">Ideate & Prototype</h2>
                                                    <p className="process-description">
                                                        Brainstormed solutions and developed low-fidelity prototypes for initial testing.
                                                    </p>
                                                    <div className="process-duration">3 Weeks</div>
                                                </div>

                                                <div className="process-card">
                                                    <h2 className="process-title">Develop & Launch</h2>
                                                    <p className="process-description">
                                                        Built the final prototype using React and Swift, ensuring technical feasibility.

                                                    </p>
                                                    <div className="process-duration">4 Weeks</div>
                                                </div>

                                                <div className="process-card">
                                                    <h2 className="process-title">Test & Iterate</h2>
                                                    <p className="process-description">
                                                        Gathered feedback through usability testing and refined the design post-launch.
                                                    </p>
                                                    <div className="process-duration">1 Week</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div ref={overviewRef}>


                                        <div className="first-frame-delta">

                                            <div className="ife-observation-layout">
                                                <div className="ife-grid-container">
                                                    <div className="ife-heading-area">
                                                        <h2 className="header-story-delta header-story-delta-long" >Observed In-Flight Touchscreen Interaction Challenges
                                                        </h2>
                                                    </div>

                                                    <div className="ife-image-area">
                                                        <div className="ife-image-background"></div>
                                                        <img
                                                            src={require('../src/assets/ProjectDelta/wakjthrough.png')}
                                                            alt="Travelers observing IFE system"
                                                            className="ife-svg-image"
                                                        />

                                                        <div className="ife-description-area">
                                                            <p className="ife-description-text">
                                                                {!expandedObservation ? (
                                                                    <>
                                                                        During my first week at Delta, I was tasked to take flights for preliminary problem discovery. I immedi...
                                                                        <span className="make-it-bold read-more" onClick={() => setExpandedObservation(true)}>
                                                                            Read more<FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '2px' }} /><FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '0px' }} />
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        During my first week at Delta, I was tasked to take flights for preliminary problem discovery. I immediately noticed passengers straining to reach the touchscreens and children quickly losing interest in games. These observations prompted me to conduct additional in-flight studies focusing on passenger interactions with the touchscreens.
                                                                        <span className="make-it-bold read-less" onClick={() => setExpandedObservation(false)}>
                                                                            Show less<FontAwesomeIcon icon={faChevronDown} size="xs" style={{ marginLeft: '2px' }} />
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">
                                            <div className="ife-observation-layout">
                                                <div className="ife-grid-container">
                                                    <div className="ife-heading-area">
                                                        <h2 className="header-story-delta header-story-delta-long" >Conducted Detailed Touchscreen System Walkthrough
                                                        </h2>
                                                    </div>

                                                    <div className="ife-image-area">
                                                        <div className="ife-image-background"></div>
                                                        <img
                                                            src={require('../src/assets/ProjectDelta/observed.svg').default}
                                                            alt="Travelers observing IFE system"
                                                            className="ife-svg-image"
                                                        />

                                                        <div className="ife-description-area">
                                                            <p className="ife-description-text">
                                                                {!expandedWalkthrough ? (
                                                                    <>
                                                                        Following initial observations, I measured seat-to-screen distances of 32-40 inches, well beyond co...
                                                                        <span className="make-it-bold read-more" onClick={() => setExpandedWalkthrough(true)}>
                                                                            Read more<FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '2px' }} /><FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '0px' }} />
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Following initial observations, I measured seat-to-screen distances of 32-40 inches, well beyond comfortable reach. Passengers spent considerable time with arms extended while browsing content, causing visible discomfort. The interface required 5-7 taps to navigate between sections or return to previous screens, extending the duration of this uncomfortable reaching.                                                                        <span className="make-it-bold read-less" onClick={() => setExpandedWalkthrough(false)}>
                                                                            Show less<FontAwesomeIcon icon={faChevronDown} size="xs" style={{ marginLeft: '2px' }} />
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">

                                            <div className="ife-observation-layout">
                                                <div className="ife-grid-container">
                                                    <div className="ife-heading-area">
                                                        <h2 className="header-story-delta header-story-delta-long" >Interviewed Passengers and Delta Engineers About Touchscreen Experiences
                                                        </h2>
                                                    </div>

                                                    <div className="ife-image-area">
                                                        <div className="ife-image-background"></div>
                                                        <img
                                                            src={require('../src/assets/ProjectDelta/interview.svg').default}
                                                            alt="Travelers observing IFE system"
                                                            className="ife-svg-image"
                                                        />

                                                        <div className="ife-description-area">
                                                            <p className="ife-description-text">
                                                                {!expandedInterviews ? (
                                                                    <>
                                                                        Conversations with frequent flyers revealed frustration with screen positioning and having to use airline...                                                                       <span className="make-it-bold read-more" onClick={() => setExpandedInterviews(true)}>
                                                                            Read more<FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '2px' }} /><FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '0px' }} />
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Conversations with frequent flyers revealed frustration with screen positioning and having to use airline-provided headphones. Delta engineers shared their ongoing challenge with Bluetooth signal interference in aircraft cabins, highlighting the technical constraints that prevented simple connectivity solutions.                                                                                                                                               <span className="make-it-bold read-less" onClick={() => setExpandedInterviews(false)}>
                                                                            Show less<FontAwesomeIcon icon={faChevronDown} size="xs" style={{ marginLeft: '2px' }} />
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">
                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story-delta" >Key Findings</h1>
                                            <div className="ife-image-area">
                                                <div className="ife-image-background"></div>
                                                <img
                                                    src={require('../src/assets/ProjectDelta/keyfinding.svg').default}
                                                    alt="Travelers observing IFE system"
                                                    className="ife-svg-image"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">
                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story-delta" >Conceptualization</h1>
                                            <p className="full-descrip">
                                                {!expandedConceptualization ? (
                                                    <>
                                                        Following research, I translated key user needs into design concepts through collaborative ideation sessions. I sketched multiple solutions addressing touchscreen accessibility, limited gaming options, and audio connectivity restrictions, leading to <span className='make-it-bold'>the smartphone-as-controller concept.</span>
                                                        <span className="make-it-bold read-more" onClick={() => setExpandedConceptualization(true)}>
                                                            Read more<FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '2px' }} /><FontAwesomeIcon icon={faChevronRight} size="xs" style={{ marginLeft: '0px' }} />
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        Following research, I translated key user needs into design concepts through collaborative ideation sessions. I sketched multiple solutions addressing touchscreen accessibility, limited gaming options, and audio connectivity restrictions, leading to <span className='make-it-bold'>the smartphone-as-controller concept.</span>
                                                        <br></br>  <br></br>I recognized that testing the touchscreen interactions would be challenging without access to actual aircraft, so we 3D printed a portable replica of an airplane seat with attached screen. This lightweight prototype enabled us to conduct realistic user testing in various settings, gathering valuable feedback without being limited by aircraft availability.
                                                        <span className="make-it-bold read-less" onClick={() => setExpandedConceptualization(false)}>
                                                            Show less<FontAwesomeIcon icon={faChevronDown} size="xs" style={{ marginLeft: '2px' }} />
                                                        </span>
                                                    </>
                                                )}
                                            </p>

                                            <div className="ife-image-area">
                                                <div className="ife-image-background"></div>
                                                <img
                                                    src={require('../src/assets/ProjectDelta/conceptual.png')}
                                                    alt="Travelers observing IFE system"
                                                    className="ife-svg-image conceptualization-space"
                                                />
                                            </div>
                                            <p className="full-descrip ">
                                                Low-fidelity prototypes were tested with users, and their feedback directly informed design iterations. Throughout the process, I maintained focus on simplicity and ease of use while ensuring the proposed solutions addressed all three key user needs identified during research.
                                            </p>
                                        </div>
                                    </div>

                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">
                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story-delta" >Proposed Solution</h1>
                                            <p className="full-descrip">
                                                The final solution addresses the three key user needs I identified:
                                            </p>


                                            <div className="ife-image-area">
                                                <div className="ife-image-background"></div>
                                                <img
                                                    src={require('../src/assets/ProjectDelta/solutionife.svg').default}
                                                    alt="Travelers observing IFE system"
                                                    className="ife-svg-image  ife-solution"
                                                />
                                            </div>

                                            <div className="ife-image-area">
                                                <div className="ife-image-background"></div>
                                                <img
                                                    src={require('../src/assets/ProjectDelta/demo.png')}
                                                    // src={('../src/assets/ProjectDelta/demo.jpg')}
                                                    alt="Travelers observing IFE system"
                                                    className="ife-svg-image conceptualization-space demo-pic"
                                                />
                                                <p className='pic-tag'>Demo Day presentation: Showcased my project to 22 members of the innovation team and leadership at Delta</p>

                                            </div>
                                        </div>
                                    </div>



                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">

                                            <div className="ife-observation-layout">
                                                <div className="ife-grid-container takeaways-grid">
                                                    <div className="ife-heading-area">
                                                        <h2 className="header-story-delta" >Lessons Learned
                                                        </h2>
                                                    </div>

                                                    <div className="ife-image-area takeaways-right ">
                                                        <div className="delta-features-container-impact">
                                                            <div className="delta-feature-header llearned-adjust">
                                                                <div className="delta-solution-circle Impact-circle learned-circle" style={{ marginRight: "20px" }}>1</div>
                                                                <p className="delta-feature-title Impact-title learned-para">
                                                                    Working on both digital and physical components gave me a deeper appreciation for the full product experience. I enjoyed designing interfaces while also considering how users would physically interact with them.
                                                                </p>
                                                            </div>
                                                            <div className="delta-feature-header llearned-adjust" style={{ marginTop: "20px" }}>
                                                                <div className="delta-solution-circle Impact-circle llearned-circle" style={{ marginRight: "20px" }}>2</div>
                                                                <p className="delta-feature-title Impact-title learned-para">
                                                                    Designing for Delta's existing infrastructure taught me to balance creative solutions with practical implementation. I gained valuable experience making design decisions that accounted for both user needs and system realities.
                                                                </p>
                                                            </div>
                                                            <div className="delta-feature-header llearned-adjust" style={{ marginTop: "20px" }}>
                                                                <div className="delta-solution-circle Impact-circle learned-circle" style={{ marginRight: "20px" }}>3</div>
                                                                <p className="delta-feature-title Impact-title learned-para">
                                                                    Collaborating directly with Delta's engineers enriched my design approach. I found that understanding technical perspectives early in the process led to more innovative and feasible solutions.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div ref={overviewRef}>
                                        <div className="first-frame-delta">
                                            <div className="ife-observation-layout">
                                                <div className="ife-grid-container">
                                                    <div className="ife-heading-area">
                                                        <h2 className="header-story-delta header-story-delta-long" >Acknowledgment
                                                        </h2>
                                                        <div className="ife-description-area">
                                                            <p className="ife-description-text achnowledgment ">
                                                                I would like to thank Joshua Jung who created and 3D printed the seat replica and worked closely with me on this project. I'm grateful to the Delta designers, engineers and strategists for their time and valuable insights. I also would like to thank my manager, Garrett Simmer, for providing the guidance and support that helped me grow tremendously throughout this project and my time at Detla.
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="ife-image-area">
                                                        <div className="ife-image-background"></div>
                                                        <img
                                                            src={require('../src/assets/ProjectDelta/groupphoto.png')}
                                                            alt="Travelers observing IFE system"
                                                            className="ife-svg-image"
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </Container>

                            </div>
                        </div>


                    </div>

                </Container>
            </Box >
    );
};

export default DeltaProject;
