import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import './LandingPage.css';
import Rive from '@rive-app/react-canvas';
import logo from './assets/maze_game.riv';
import logo2 from './assets/gang2.riv';
import { useLocation, useNavigate } from 'react-router-dom';
import 'animate.css';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import GradientText from './GradientText'
import MetallicCard from './MetallicCard';
import MetallicDeltaCard from './MetallicDeltaCard';
import ArrowAnimation from './ArrowAnimation';
import CompanyAnimation from './CompanyAnimation';

function LandingPage({ active, setActive, menuOpen, setMenuOpen }) {
  // State to handle active page and menu
  const location = useLocation();
  const navigate = useNavigate();

  // Determine content alignment and height based on active page
  const contentStyle = active === 'play' ? {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Align to top for 'play' page
    alignItems: 'center',
  } : {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref6, inView6] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref7, inView7] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref8, inView8] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref9, inView9] = useInView({ triggerOnce: true, threshold: 0 });

  return (
    // The outer container, ThemeProvider, and NavigationMenu are now handled by the App layout
    // Only render the content part here
    <Box className="content" sx={contentStyle}>
      <div className={active === 'work' ? 'section-active' : 'section-hidden'}>
        <>
          {/* Work Page Content */}
          <div className="title-container">
            <GradientText />
            <div className="subtitle-container">
              <Box component="h2" className="animate__animated animate__fadeInDown subtitle-left increase">A UX professional with experience in both <span className="subtitle-main">design </span> and <span className="subtitle-main">frontend</span> development.</Box>
            </div>
          </div>

          <CompanyAnimation />

          <div ref={ref8}>
            <ArrowAnimation />
          </div>

          <MetallicCard
            inView={inView1}
            cardRef={ref1}
            logoSrc={require('../src/assets/project3/delta.png')}
            title="Delta Air Lines"
            tags={['UX Design', 'UX Engineering', 'Mobile','R&D' ]}
            description="Designed & developed mobile control for Delta's in-flight entertainment touchscreens"
            projectLink="/delta"
            imageSrc={require('../src/assets/project1/deltamock.png')}
            accentColor="#e31837"
            logoSize={24}
            imageHeight="500px"
            imageWidth="130%"
            imageTransform="translate(1.9rem, -1.3rem)"
            imageObjectFit="contain"
            imageObjectPosition="center"
            imageClassName="card-image-delta"
          />

          <MetallicCard
            inView={inView2}
            cardRef={ref2}
            logoSrc={require('../src/assets/project1/microsoft.png')}
            title="Microsoft"
            tags={['UX Research', 'UX Design','Mobile', 'B2C']}
            description="Enhanced Microsoft Teams mobile app with business-customer communication features"
            projectLink="/project"
            imageSrc={require('../src/assets/project1/micromockup.png')}
            accentColor="#A3FD99"
            logoSize={22}
            imageHeight="110%"
            imageWidth="110%"
            imageTransform="translate(1rem, -2.2rem)"
            imageObjectFit="cover"
            imageObjectPosition="center"
          />

          <MetallicCard
            inView={inView3}
            cardRef={ref3}
            logoSrc={require('../src/assets/project4/bee.png')}
            title="IBM"
            tags={['UX Research', 'UX Design', 'Web', 'LLM']}
            description="Building a scalable web interface for evaluating LLM-generated outputs"
            projectLink="/ibm"
            imageSrc={require('../src/assets/project1/IBMA.png')}
            accentColor="#226CE0"
            logoSize={24}
            imageHeight="150%"
            imageWidth="150%"
            imageTransform="translate(4rem, -2.5rem)"
            imageObjectFit="contain"
            imageObjectPosition="center"
          />

          <MetallicCard
            inView={inView4}
            cardRef={ref4}
            logoSrc={require('../src/assets/project2/airport.png')}
            title="Atlanta Airport"
            tags={['UX Research', 'UX Design', 'Accessibility']}
            description="Optimized airport experience for reduced mobility travelers with navigation app"
            projectLink="/atl-airport"
            imageSrc={require('../src/assets/project1/atlmockup.png')}
            accentColor="#BA0C2F"
            className="card-airport"
            logoSize={24}
            imageHeight="110%"
            imageWidth="110%"
            imageTransform="translate(1rem, -2.2rem)"
            imageObjectFit="cover"
            imageObjectPosition="center"
          />
        </>
      </div>

      <div className={active === 'play' ? 'section-active' : 'section-hidden'}>
        <>
          {/* Play Page Content */}
          <div style={{ textAlign: 'center' }}>
            <GradientText
              text="I also enjoy coding on the side!"
              customStyles={{
                fontSize: '85px',
                marginTop: '3rem',
                marginBottom: '2.5rem',
              }}
            />
            <Box component="h2" className="animate__animated animate__fadeInDown subtitle-left">Check out some of my projects! <FontAwesomeIcon icon={faAngleDown} className="bouncing-icon" /></Box>
          </div>

          <Box component="h1" className={`case-studies coding-project ${inView9 ? 'animate__animated animate__fadeIn animate__delay-0.8s' : ''}`} ref={ref9}> </Box>

          <MetallicCard
            inView={inView5}
            cardRef={ref5}
            logoSrc={require('../src/assets/logos/flutter.png')}
            title="Emora Chat"
            tags={['Flutter', 'Frontend', 'Mobile', 'AI']}
            description="Designed & developed an AI student chatbot app serving 50,000+ student interactions"
            projectLink="https://apps.apple.com/us/app/emora-chat/id1638974060"
            imageSrc={require('../src/assets/project1/emorachat.png')}
            accentColor="#027DFD"
            logoSize={24}
          />

          <MetallicCard
            inView={inView6}
            cardRef={ref6}
            logoSrc={require('../src/assets/logos/react.png')}
            title="Interactivity@GT"
            tags={['React', 'Fullstack', 'Responsive', 'Web']}
            description="Developed a registration system & participant platform for GT research showcase event"
            projectLink="https://interactivity-gt.vercel.app"
            imageSrc={require('../src/assets/project1/interactmock.png')}
            accentColor="#61DAFB"
            logoSize={24}
            imageHeight="130%"
            imageWidth="130%"
            imageTransform="translate(8rem, -0.5rem)"
            imageObjectFit="contain"
            imageObjectPosition="center"
          />

          <MetallicCard
            inView={inView7}
            cardRef={ref7}
            logoSrc={require('../src/assets/logos/flutter.png')}
            title="Trini"
            tags={['Flutter', 'Fullstack', 'Mobile']}
            description="Developed mobile fitness app for tracking powerlifting workouts and PRs"
            projectLink="https://github.com/aelaser/trini.git"
            imageSrc={require('../src/assets/project1/trini.png')}
            accentColor="#027DFD"
            logoSize={24}
          />

          <MetallicCard
            inView={inView8}
            cardRef={ref8}
            logoSrc={require('../src/assets/logos/nextjs.png')}
            title="Hulu"
            tags={['NextJS', 'Frontend', 'Streaming', 'Web']}
            description="Redesigned and built Hulu clone with content filtering and dynamic UI"
            projectLink="https://streaming-service-drab.vercel.app"
            imageSrc={require('../src/assets/project1/hulumock.png')}
            accentColor="#eeeeee"
            logoSize={24}
            imageHeight="130%"
            imageWidth="130%"
            imageTransform="translate(8rem, -0.5rem)"
            imageObjectFit="contain"
            imageObjectPosition="center"
          />
        </>
      </div>
    </Box>
  );
}

export default LandingPage;